import { FC } from 'react'
import { ButtonWithIcon, Heading } from '@netpurpose/np-ui'
import { CreatePortfolioAction } from '#components/portfolio/CreatePortfolioAction'
import { ModalActionTrigger } from '#components/portfolio/portfolioTypes'
import { EmptyContainer, EmptyHeadingText } from './PortfolioTable.style'

const CreateButton: FC<ModalActionTrigger> = ({ onClick }) => (
  <ButtonWithIcon
    level="tertiary"
    icon="Plus"
    iconAlt="Create portfolio icon"
    color="black"
    onClick={onClick}
  >
    Create a Portfolio
  </ButtonWithIcon>
)

export const EmptyPortfolioTable = () => (
  <>
    <EmptyContainer>
      <div>
        <EmptyHeadingText level={1}>Create a Portfolio</EmptyHeadingText>
        <Heading level={3} color="typeSecondary">
          Measure your contribution to our global goals.
        </Heading>
      </div>
      <CreatePortfolioAction renderAction={CreateButton} />
    </EmptyContainer>
  </>
)
