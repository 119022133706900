import { DefaultOptionType } from 'antd/es/select'
import { Field, FieldProps } from 'formik'
import { AutoCompleteProps } from 'formik-antd'
import { FC, FocusEvent } from 'react'
import { noop, valueIsDefined } from '@netpurpose/utils'
import { CreateNewOptionContainer } from './AutoCompleteWithNew.style'
import { AutoComplete } from './FormInput'

const CREATE_NEW_OPTION_NAME = 'new'

type Option =
  | DefaultOptionType
  | {
      name: string
      value: string
      label: React.ReactNode
    }

const isCreateNewOption = (option: Option): boolean =>
  option && option?.name === CREATE_NEW_OPTION_NAME

const CreateNewOption: FC = () => <CreateNewOptionContainer>+ Create new</CreateNewOptionContainer>

const newOption = {
  name: CREATE_NEW_OPTION_NAME,
  value: '___CREATE_NEW___',
  label: <CreateNewOption />,
}
const getOptions = (options?: Option[]): Option[] => {
  const opt = options?.length ? options.filter((option) => option.value !== '___CREATE_NEW___') : []
  return [newOption, ...opt]
}

export const AutoCompleteWithNew = ({
  name,
  validate,
  fast,
  onChange,
  onBlur,
  options,
  onSelect,
  ...restProps
}: AutoCompleteProps) => {
  const handleFilterOption: AutoCompleteProps['filterOption'] = (value, option) => {
    if (!option?.label || isCreateNewOption(option)) {
      return true
    }
    return option.label.toString().toLowerCase().includes(value.toLowerCase())
  }

  const handleFieldChange: (form: FieldProps['form']) => AutoCompleteProps['onChange'] =
    (form) => (value, option) => {
      if (!Array.isArray(option) && isCreateNewOption(option)) {
        return
      }
      form.setFieldValue(name, valueIsDefined(value) ? value.valueOf() : value)
      if (onChange) {
        onChange(value, option)
      }
    }

  const getHandleSelect =
    (form: FieldProps['form']) => (currentValue: string, option: DefaultOptionType) => {
      if (isCreateNewOption(option)) {
        return form.setFieldValue(name, currentValue)
      }

      if (!onSelect || !option.value) {
        return
      }

      onSelect(option.value, option)
      return
    }

  return (
    <Field name={name} validate={validate} fast={fast}>
      {({ field: { value }, form }: FieldProps) => {
        const label = options?.find((opt) => opt.value === value)?.label || value
        const opts = getOptions(options)
        const handleSelect = getHandleSelect(form)

        return (
          <AutoComplete
            name={name}
            options={opts}
            filterOption={handleFilterOption}
            value={label}
            onChange={handleFieldChange(form) || noop}
            onSelect={(_val: unknown, opt: DefaultOptionType) => handleSelect(value, opt)}
            onBlur={(v: FocusEvent<HTMLElement, Element>) => {
              form.setFieldTouched(name)
              if (onBlur) {
                onBlur(v)
              }
            }}
            {...restProps}
          />
        )
      }}
    </Field>
  )
}

export default AutoCompleteWithNew
