import { Tooltip } from '../Tooltip'
import { badgeContent, PortfolioBadge } from './PortfolioBadge'
import { OverlappingBadges } from './PortfolioBadge.style'

export const FoFPortfolioBadge = ({ withoutTooltip = false }: { withoutTooltip?: boolean }) => {
  const types = ['fund of funds', 'fund', 'fund'] as const
  const title = badgeContent[types[0]]?.title
  const maxTypes = types.slice(0, 3)

  return (
    <Tooltip placement="right" title={withoutTooltip ? '' : title}>
      <OverlappingBadges>
        {maxTypes.map((type, idx) => {
          // NOTE: should not be higher than modals' zIndex
          const startingZIndex = 10
          return (
            <PortfolioBadge
              key={`${type}-${idx}`}
              type={type}
              style={idx === 0 ? { zIndex: startingZIndex } : { zIndex: startingZIndex - idx }}
              withoutTooltip
            />
          )
        })}
      </OverlappingBadges>
    </Tooltip>
  )
}
