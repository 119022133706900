export type ModalActionTrigger = {
  onClick: () => void
}

// NOTE: we don't want "fund of funds" to appear in type filters
export const portfolioTypeMap: Record<string, string> = {
  private: 'Portfolio',
  benchmark: 'Benchmark',
  watchlist: 'Watchlist',
  fund: 'Fund',
}
