import styled from 'styled-components'
import { Button } from '@netpurpose/np-ui'
import { StyledDivider } from '@netpurpose/np-ui/src/components/PageSection/PageSection.style'
import { CompareButton } from '#components/compare/CompareButton'
import { CreatePortfolioAction } from '#components/portfolio/CreatePortfolioAction'
import { DeletePortfolioAction } from '#components/portfolio/DeletePortfolioAction'
import { AnalyticsButtonName } from '#types/analytics'

const ActionButton = styled(Button)`
  height: 2rem;
  width: 16rem;
  line-height: 1rem;
`

const CreateButton = ({ onClick }: { onClick: () => void }) => (
  <ActionButton onClick={onClick} size="small">
    Create Portfolio
  </ActionButton>
)

export const Actions = ({ selectedPortfolioIds }: { selectedPortfolioIds: number[] }) => (
  <>
    <CompareButton
      selectedIds={selectedPortfolioIds}
      type="portfolio"
      analyticsButtonName={AnalyticsButtonName.ComparePortfolioList}
    />

    <StyledDivider type="vertical" />
    <DeletePortfolioAction selectedPortfolioIds={selectedPortfolioIds} />
    <StyledDivider type="vertical" />
    <CreatePortfolioAction renderAction={CreateButton} />
  </>
)
