import { FC } from 'react'
import { useModalContext } from '@netpurpose/core'
import { Modal } from '@netpurpose/np-ui'
import { ModalActionTrigger } from '#components/portfolio/portfolioTypes'
import { useAnalytics } from '#context/analytics'
import { AnalyticsButtonName, AnalyticsEventName } from '#types/analytics'
import { CreatePortfolioContent } from './CreatePortfolioContent'

export const CreatePortfolioModal: FC = () => {
  const { isModalOpen, closeModal } = useModalContext()
  return (
    <Modal
      open={isModalOpen.createPortfolio}
      title="Create portfolio"
      onClose={() => closeModal('createPortfolio')}
      size="large"
    >
      <CreatePortfolioContent />
    </Modal>
  )
}

type Props = {
  renderAction: FC<ModalActionTrigger>
}

export const CreatePortfolioAction: FC<Props> = ({ renderAction: Action }) => {
  const { openModal } = useModalContext()
  const analytics = useAnalytics()

  return (
    <Action
      onClick={() => {
        analytics?.logEvent(AnalyticsEventName.ButtonClick, {
          button_name: AnalyticsButtonName.CreatePortfolioOpenModal,
        })
        openModal('createPortfolio')
      }}
    />
  )
}
