import { Icon, LinkText, Modal } from '@netpurpose/np-ui'
import { useAnalytics } from '#context/analytics'
import { AnalyticsButtonName, AnalyticsEventName } from '#types/analytics'
import { IconTextContainer, ModalText, TextList } from './CreatePortfolioAction.style'

export const DirectText = ({ isCreateSnapshot }: { isCreateSnapshot?: boolean }) => {
  const analytics = useAnalytics()
  return (
    <>
      <Modal.HeaderText>
        Direct holdings {isCreateSnapshot ? 'snapshot' : 'portfolio'}
      </Modal.HeaderText>
      <div>
        <ModalText>
          To create a {isCreateSnapshot ? 'snapshot' : 'portfolio'} you will need to provide a .xlsx
          file with a list of holdings, with the holding weights.
        </ModalText>
        <TextList>
          <li style={{ width: 'fit-content' }}>
            <a
              href="/downloads/direct-holdings-upload-template.xlsx"
              download="Net Purpose - Create your direct holdings portfolio template.xlsx"
              onClick={() =>
                analytics?.logEvent(AnalyticsEventName.ButtonClick, {
                  button_name: AnalyticsButtonName.DownloadPortfolioTemplateDirect,
                })
              }
            >
              <LinkText>
                <IconTextContainer>
                  Download the 'Portfolio upload' template
                  <Icon icon="Download" alt="Download icon" inheritColor />
                </IconTextContainer>
              </LinkText>
            </a>
          </li>
          <li>Go to the 'Portfolio template' sheet. </li>
          <li>
            Fill in the template with holding names, ISINs and weights. Use one row per holding. The
            total portfolio weights may be below but not exceed 100%. Cash holdings may be removed
            or included by using CCY CASH as the ISIN.
          </li>
          <li>Save the excel as .xlsx.</li>
          <li>Upload the .xlsx below.</li>
        </TextList>
      </div>
    </>
  )
}

export const FundsText = () => {
  const analytics = useAnalytics()
  return (
    <>
      <Modal.HeaderText>Fund of funds portfolio</Modal.HeaderText>
      <div>
        <TextList>
          <li>
            <a
              href="/downloads/fund-of-funds-upload-template.xlsx"
              download="Net Purpose - Create your fund of funds portfolio template.xlsx"
              onClick={() =>
                analytics?.logEvent(AnalyticsEventName.ButtonClick, {
                  button_name: AnalyticsButtonName.DownloadPortfolioTemplateFundOfFunds,
                })
              }
            >
              <LinkText>
                <IconTextContainer>
                  Download the 'Fund of funds portfolio upload' template
                  <Icon icon="Download" alt="Download icon" inheritColor />
                </IconTextContainer>
              </LinkText>
            </a>
          </li>
          <li>Go to the 'Fund of funds template' sheet. </li>
          <li>
            Fill in the template with fund names, ISINs and weights. You may use an ISIN or a unique
            code for the ISIN field for funds. This is your fund of funds portfolio. The total
            weight of your portfolio may be below 100% but not above. For cash holdings, you may
            remove or include using CCY CASH as the ISIN.
          </li>
          <li>
            For your first fund, use the 'Fund holdings template' sheet and rename the sheet with
            the fund ISIN field used in Step 2. Fill in the template with security names, ISINs and
            weights. These are the holdings within your fund.
          </li>
          <li>
            Duplicate the 'Fund holdings template' sheet and repeat step 4 for all the funds in your
            fund of funds portfolio.
          </li>
          <li>Save the excel as .xlsx.</li>
          <li>Upload the .xlsx below.</li>
        </TextList>
      </div>
    </>
  )
}
