import { useQueryClient } from '@tanstack/react-query'
import { FC, Fragment, useState } from 'react'
import { useDeletePortfolio } from '@netpurpose/api'
import { useModalContext } from '@netpurpose/core'
import { Button, ErrorMessage, Icon, Modal, withTooltip } from '@netpurpose/np-ui'
import { DeleteButtonContainer } from './DeletePortfolioAction.style'

type Props = {
  selectedPortfolioIds: number[]
  setSelectedPortfolioIds: (selectedPortfolioIds: number[]) => void
  selectedPortfolioNames: string[]
}

export const DeletePortfolioModal = ({
  selectedPortfolioIds,
  setSelectedPortfolioIds,
  selectedPortfolioNames,
}: Props) => {
  const [error, setError] = useState<string | null>(null)
  const { isModalOpen, closeModal: closeActualModal } = useModalContext()
  const closeModal = () => closeActualModal('deletePortfolio')

  const { deletePortfolio } = useDeletePortfolio({
    onError: setError,
  })

  const queryClient = useQueryClient()

  const deletePortfolios = () => {
    selectedPortfolioIds.forEach((portfolioId) => {
      deletePortfolio(
        { portfolioId },
        {
          // will execute only once for the last item (we don't want this to be called for each item)
          // https://tanstack.com/query/latest/docs/framework/react/guides/mutations#consecutive-mutations
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ['portfolios'],
            })
            setSelectedPortfolioIds([])
            closeModal()
          },
        },
      )
    })
  }

  return (
    <Modal
      open={isModalOpen.deletePortfolio}
      title="Delete Portfolio"
      onClose={closeModal}
      size="medium"
    >
      <Modal.ContentContainer>
        <p style={{ fontSize: '1rem' }}>
          Are you sure you want to delete the selected portfolio
          {selectedPortfolioNames.length > 1 && 's'}{' '}
          {selectedPortfolioNames.map((p, index) => (
            <Fragment key={index}>
              "<strong>{p}</strong>"{index < selectedPortfolioNames.length - 1 && ', '}
            </Fragment>
          ))}
          ?
        </p>
        <p style={{ fontSize: '1rem' }}>
          This action cannot be undone. Click "Cancel" to keep it or "Delete permanently" to
          proceed.
        </p>
        {error && <ErrorMessage error={error} />}
      </Modal.ContentContainer>
      <Modal.FooterContainer>
        <Button level="danger" onClick={deletePortfolios}>
          Delete permanently
        </Button>
        <Button level="primary" onClick={closeModal}>
          Cancel
        </Button>
      </Modal.FooterContainer>
    </Modal>
  )
}

const DeleteButtonWrapper = withTooltip(DeleteButtonContainer)

export const DeletePortfolioAction: FC<Pick<Props, 'selectedPortfolioIds'>> = ({
  selectedPortfolioIds,
}) => {
  const { openModal } = useModalContext()

  const isDisabled = selectedPortfolioIds.length === 0
  const handleClick = () => {
    if (isDisabled) {
      return
    }
    openModal('deletePortfolio')
  }

  return (
    <>
      <DeleteButtonWrapper
        data-testid="delete-button"
        disabled={isDisabled}
        onClick={handleClick}
        tooltip="Select a portfolio to delete"
        showTooltip={isDisabled}
      >
        <Icon
          icon="Delete"
          alt="Delete portfolio"
          color="semanticNegative"
          style={isDisabled ? { opacity: 0.4 } : {}}
        />
      </DeleteButtonWrapper>
    </>
  )
}
