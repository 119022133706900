import { valueIsDefined } from '../helpers'

export const formatInputWithThousandSeparators = (value: string | number | undefined) => {
  if (!valueIsDefined(value)) {
    return ''
  }
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const parseInputWithThousandSeparators = (value: string | undefined) => {
  if (!valueIsDefined(value) || value === '') {
    return undefined
  }
  return Number(value.replace(/[^0-9]/g, ''))
}
