import { useState } from 'react'
import {
  CreatePortfolio,
  Portfolio,
  usePaginatedPortfolios,
  useUploadPortfolio,
} from '@netpurpose/api'
import { useLoggedIn, useModalContext } from '@netpurpose/core'
import { Button, Icon, LinkText, SwitchButton } from '@netpurpose/np-ui'
import { useAnalytics } from '#context/analytics'
import { useTasks } from '#context/tasks'
import { AnalyticsButtonName, AnalyticsEventName } from '#types/analytics'
import { canAccessFundOfFunds } from '#utils'
import {
  BottomContainer,
  DragUpload,
  LabelText,
  ModalBodyContainer,
  ModalHeaderContainer,
  ModalText,
  Separator,
  StepCounter,
  UploadContainer,
  UploadedFileContainer,
} from './CreatePortfolioAction.style'
import { CreatePortfolioForm } from './CreatePortfolioForm'
import { DirectText, FundsText } from './UploadInstructions'

export type UploadType = 'direct' | 'funds'

const UploadHoldings = ({
  step,
  setStep,
  portfolioFile,
  setPortfolioFile,
  setErrorMessage,
  isPending,
  uploadType,
  setUploadType,
  userCanAccessFundOfFunds,
  isCreateSnapshot,
}: {
  step: 1 | 2
  setStep: (step: 1 | 2) => void
  portfolioFile: File | undefined
  setPortfolioFile: (file: File | undefined) => void
  setErrorMessage: (message: string | null) => void
  isPending: boolean
  uploadType: UploadType
  setUploadType: (uploadType: UploadType) => void
  userCanAccessFundOfFunds: boolean
  isCreateSnapshot: boolean
}) => (
  <ModalBodyContainer $visible={step === 1}>
    {userCanAccessFundOfFunds && !isCreateSnapshot && (
      <SwitchButton
        options={[
          {
            label: 'Direct holdings',
            value: 'direct',
          },
          {
            label: 'Fund of funds portfolio',
            value: 'funds',
          },
        ]}
        defaultValue={uploadType}
        onClick={(value) => {
          setUploadType(value)
          setPortfolioFile(undefined)
          setErrorMessage(null)
        }}
        fullWidth
        urlSync={false}
      />
    )}
    {uploadType === 'direct' ? <DirectText isCreateSnapshot={isCreateSnapshot} /> : <FundsText />}
    <div>
      <LabelText>Upload file</LabelText>
      <DragUpload
        accept={uploadType === 'direct' ? '.xlsx, .csv' : '.xlsx'}
        maxCount={1}
        // NOTE: to prevent default POST request being sent on file change.
        customRequest={() => {}}
        onChange={({ file }) => setPortfolioFile(file.originFileObj)}
        data-testid="file-input"
        // Prevent antd default file list from being shown
        fileList={[]}
      >
        {portfolioFile ? (
          <UploadedFileContainer>
            <Icon icon="Excel" alt="Excel icon" viewbox="0 0 33 32" width={33} height={32} />
            <div>{portfolioFile.name}</div>
            <Icon
              icon="Delete"
              alt="Remove file"
              onClick={(event) => {
                // Prevent overlapping click events of DragUpload and this from both triggering
                event.stopPropagation()
                setPortfolioFile(undefined)
              }}
            />
          </UploadedFileContainer>
        ) : (
          <UploadContainer>
            <Icon
              icon="Upload"
              alt="Upload file"
              viewbox="0 0 40 39"
              width={40}
              height={39}
              color="architecture5"
            />
            <span>
              Drag & drop files or <LinkText>select a file</LinkText>
            </span>
            <span>Supported format: .xlsx{uploadType === 'direct' && ' and .csv'}</span>
          </UploadContainer>
        )}
      </DragUpload>
    </div>
    <BottomContainer>
      <span>
        Contact
        <a href="mailto:support@netpurpose.com" target="_blank">
          <LinkText> support@netpurpose.com </LinkText>
        </a>
        for support.
      </span>
      <Button
        type="primary"
        onClick={() => setStep(2)}
        disabled={portfolioFile === undefined || isPending}
        loading={isPending}
      >
        Next
      </Button>
    </BottomContainer>
  </ModalBodyContainer>
)

const getHeaderText = ({
  step,
  userCanAccessFundOfFunds,
  isCreateSnapshot,
}: {
  step: 1 | 2
  userCanAccessFundOfFunds: boolean
  isCreateSnapshot: boolean
}) => {
  if (step === 1) {
    return userCanAccessFundOfFunds && !isCreateSnapshot ? 'Select portfolio type' : ''
  }
  return `Add ${isCreateSnapshot ? 'snapshot' : 'portfolio'} details`
}

type Props = {
  preselectedPortfolio?: Portfolio
}

export const CreatePortfolioContent = ({ preselectedPortfolio }: Props) => {
  const { createPortfolio } = useTasks()
  const { closeModal } = useModalContext()
  const analytics = useAnalytics()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [uploadType, setUploadType] = useState<UploadType>(
    preselectedPortfolio?.type === 'fund of funds' ? 'funds' : 'direct',
  )

  const { user } = useLoggedIn()
  const userCanAccessFundOfFunds = canAccessFundOfFunds(user)

  const { uploadPortfolio, isPending } = useUploadPortfolio({
    isFoFUpload: uploadType === 'funds',
    onSuccess: (token, uploadPortfolioResponse) => {
      createPortfolio(token, uploadPortfolioResponse)
      closeModal(preselectedPortfolio ? 'createPortfolioSnapshot' : 'createPortfolio')
    },
    onError: (err) => setErrorMessage(err),
  })
  const [step, setStep] = useState<1 | 2>(1)
  const [portfolioFile, setPortfolioFile] = useState<File>()

  const handleUpload = (formValues: {
    name: string
    type: CreatePortfolio['type']
    totalValue: number
    valuationDate: Date
  }) => {
    if (!portfolioFile) {
      return
    }
    uploadPortfolio({
      ...formValues,
      currency: 'USD',
      file: portfolioFile,
    })

    if (preselectedPortfolio) {
      analytics?.logEvent(AnalyticsEventName.ButtonClick, {
        button_name:
          uploadType === 'funds'
            ? AnalyticsButtonName.CreateSnapshotFundOfFunds
            : AnalyticsButtonName.CreateSnapshotDirectHoldings,
        portfolio_id: preselectedPortfolio.id,
      })
    } else {
      analytics?.logEvent(AnalyticsEventName.ButtonClick, {
        button_name:
          uploadType === 'funds'
            ? AnalyticsButtonName.CreatePortfolioFundOfFunds
            : AnalyticsButtonName.CreatePortfolioDirectHoldings,
      })
    }
  }

  const { data: portfolios } = usePaginatedPortfolios({
    // None of our clients are likely to have anywhere near this many portfolios,
    // but on the off-chance a user does, this query will be expensive for much
    // higher numbers.
    perPage: 999,
    useUrlSync: false,
  })

  return (
    <>
      <ModalHeaderContainer>
        <ModalText>
          {getHeaderText({
            step,
            userCanAccessFundOfFunds,
            isCreateSnapshot: !!preselectedPortfolio,
          })}
        </ModalText>
        <div>
          {step === 1 ? (
            <StepCounter $active={true}>1</StepCounter>
          ) : (
            <Icon icon="TickInCircle" alt="Completed" height={20} width={20} />
          )}
          <ModalText>Upload holdings</ModalText>
          <Separator>―</Separator>
          <StepCounter $active={step === 2}>2</StepCounter>
          <ModalText>{preselectedPortfolio ? 'Snapshot' : 'Portfolio'} details</ModalText>
        </div>
      </ModalHeaderContainer>
      <UploadHoldings
        step={step}
        setStep={setStep}
        portfolioFile={portfolioFile}
        setPortfolioFile={setPortfolioFile}
        setErrorMessage={setErrorMessage}
        isPending={isPending}
        uploadType={uploadType}
        setUploadType={setUploadType}
        userCanAccessFundOfFunds={userCanAccessFundOfFunds}
        isCreateSnapshot={!!preselectedPortfolio}
      />
      <CreatePortfolioForm
        handleUpload={handleUpload}
        setStep={setStep}
        fileName={portfolioFile?.name}
        visible={step === 2}
        isPending={isPending}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        uploadType={uploadType}
        portfolios={portfolios.results || []}
        preselectedPortfolio={preselectedPortfolio}
      />
    </>
  )
}
